<template>
  <div id="add-user">
    <h1>{{ editMode ? $t("USER.EDIT_USER") : $t("USER.ADD_USER") }}</h1>
    <form class="mt-5" v-if="!editMode || (editMode && loadedUser)">
      <div class="row">
        <div class="col-6">
          <b-form-group
            id="example-input-group-0"
            label="Username"
            label-for="example-input-0"
          >
            <b-form-input
              id="example-input-0"
              name="example-input-0"
              v-model="$v.user.username.$model"
              :state="validateState('username')"
              aria-describedby="input-0-live-feedback"
              placeholder="Username"
            ></b-form-input>

            <b-form-invalid-feedback id="input-0-live-feedback">
              Username is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group
            id="example-input-group-1"
            label="E-mail"
            label-for="example-input-1"
          >
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              v-model="$v.user.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
              placeholder="Email address"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Email is required and a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-6">
          <b-form-group
            id="example-input-group-2"
            label="Password"
            label-for="example-input-2"
          >
            <b-form-input
              type="password"
              id="example-input-2"
              name="example-input-2"
              v-model="$v.user.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
              placeholder="Password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">
              Password is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            id="example-input-group-2"
            label="Role"
            label-for="example-input-2"
          >
            <b-form-select v-model="user.role" :options="roles"></b-form-select>

            <b-form-invalid-feedback id="input-2-live-feedback">
              Role is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button
          :disabled="submitting"
          @click.prevent="onSubmit"
          class="btn btn-primary"
          id="kt_submit"
        >
          {{ $t("COMMON.SUBMIT") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  created() {
    if (this.$route.name === "Edit User") {
      this.editMode = true;
      this.getEvent({ id: this.$route.params.id });
    } else {
      this.editMode = false;
      this.resetFields();
    }
  },
  data() {
    return {
      // Remove this dummy login info
      user: {
        email: "",
        password: "",
        username: "",
        role: null,
      },
      submitting: false,
      roles: [
        { value: null, text: "Select Role" },
        { value: "admin", text: "Admin" },
        { value: "event_manager", text: "Event Manager" },
      ],
      editMode: false,
    };
  },
  validations: {
    user: {
      username: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
      role: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      saveUser: "saveUser",
    }),
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    resetFields() {
      this.user = {
        username: null,
        email: null,
        password: null,
        role: null,
      };
      this.$v.user.$reset();
    },
    onSubmit() {
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }

      this.submitting = true;

      const username = this.$v.user.username.$model;
      const email = this.$v.user.email.$model;
      const password = this.$v.user.password.$model;
      const role = [this.$v.user.role.$model];

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add("kt-spinner");

      const data = {
        user: {
          email: email,
          password: password,
          username: username,
          role,
        },
        vm: this,
      };

      // dummy delay
      setTimeout(() => {
        // send register request
        // this.$store.dispatch('saveUser', data);
        this.saveUser(data);

        submitButton.classList.remove("kt-spinner");
      }, 2000);
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    isDisabled() {
      if (
        this.user.username === "" ||
        this.user.username === null ||
        this.user.email === "" ||
        this.user.email === null ||
        this.user.password === "" ||
        this.user.password === null ||
        this.user.role === null
      ) {
        return true;
      } else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#add-user {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }
}
</style>
